<template>
  <div class="carousel">
    <div class="top-controls">
      <span
        v-for="(item, index) in sourceList"
        :key="item.key"
        :class="{ active: currentIndex === index }"
        @mouseenter="pauseCarousel(index)"
        @mouseleave="startCarousel"
      >
        {{ item.name }}
      </span>
    </div>
    <div class="carousel-image">
      <img
        :src="sourceList[currentIndex].url"
        :alt="sourceList[currentIndex].name"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sourceList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    startCarousel() {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.sourceList.length;
      }, 3000);
    },
    pauseCarousel(index) {
      clearInterval(this.intervalId);
      this.currentIndex = index;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="less" scoped>
.carousel {
  width: 100%;
  text-align: center;
}

.top-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  span {
    position: relative;
    margin: 0 10px;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background-color: #e83021;
      position: absolute;
      left: 0;
      bottom: -6px;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.3s ease;
    }

    &.active::after {
      transform: scaleX(1);
    }
  }
}

.top-controls span {
  margin: 0 10px;
  cursor: pointer;
}

.top-controls .active {
  color: #e83021;
}

.carousel-image {
  width: 960px;
  height: 520px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
