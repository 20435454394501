<template>
  <div class="adas pb50">
    <div class="banner">
      <ImageWithPlaceholder src="/image/adas/adas.jpg" alt="Loaded Image" />
      <h1 class="banner-title">Integrated ADAS Solutions</h1>
      <div class="overview-box">
        <a href="#southlake" class="southlake">
          <span class="title">Intelligent Front Camera Module </span>
          <br />
          <span class="title min-title">Southlake</span>
        </a>
        <a href="#westlake" class="westlake">
          <span class="title">Cost-Effective ADAS Domain Controller </span>
          <br />
          <span class="title min-title">Westlake</span>
        </a>
        <a href="#eastlake" class="eastlake">
          <span class="title">High-Performance ADAS Domain Controller</span>
          <br />
          <span class="title min-title">Eastlake</span>
        </a>
      </div>
    </div>
    <div class="info-container mt120" id="info">
      <div class="content-wrapper">
        <div class="describe">
          <p class="p-text">
            The automotive industry is experiencing revolutionary changes around
            the world. As one of the leading automotive visual sensing solutions
            providers, ArcSoft has worked with camera makers and SoC partners to
            develop a series of integrated ADAS product solutions tailored to
            various OEM customer needs and their budgetary requirements. OEMs
            can use the ArcSoft integrated ADAS solutions to offer advanced
            driving assistance functions.
          </p>
          <p class="p-text">
            With the ArcSoft integrated ADAS solutions, OEM can meet regional
            regulatory requirements such as GSR (e.g., FCW, AEB, ISA) or E-NCAP
            evaluation criteria, and provide safer and more comfortable driving
            with ACC, LCC, and NOA functions.
          </p>
        </div>
      </div>
    </div>
    <div class="southlake-container pt80 mt50" id="southlake">
      <div class="content-wrapper">
        <h2 class="h2-text nowrap color-fa">
          <span class="icon"></span>
          <span>Intelligent Front Camera Module</span>
        </h2>
        <h3 class="h3-title mb16">Southlake</h3>
        <p class="p-text color-fa southlake-text">
          Southlake is a standalone intelligent ADAS camera module developed on
          cost-effective SoC platforms to support advanced driving assistance
          functions that comply with regional regulations and general E-NCAP
          evaluation criteria. It also has an additional camera interface and
          capacity to support the DMS functions including DDAW & ADDW.
        </p>
        <h3 class="functions-white h3-text">Functions</h3>
        <p class="p-text-1">
          • Active Safety - AEB/FCW, LKA/ELK (Oncoming/Overtaking/Road Edge &
          Solid), ISA, TSR/TFL and DMS (DDAW & ADDW).
        </p>
        <p class="p-text-1">• Driving Assist - ACC, LCC, IHC/AHBC.</p>
        <p class="p-text-1">
          • ILC, BSD, DOW, RCTA (with additional two rear corner radars).
        </p>
      </div>
      <!-- <p class="bottom-title p-text mt36">
        Can integrate with radar/ultrasonic sensors depending on user scenarios
      </p> -->
    </div>
    <div class="westlake-container pt80" id="westlake">
      <div class="content-wrapper">
        <h2 class="h2-text">
          <span class="icon"></span>
          <span>Cost-Effective ADAS Domain Controller</span>
        </h2>
        <h3 class="h3-title mb16">Westlake</h3>
        <p class="p-text westlake-text mb40">
          Based on cost-effective platforms such as TDA4, ArcSoft developed
          efficient driving and parking perception algorithms to offer
          cost-effective ADAS solutions for NOA-Highway, Vision-Only or camera
          and USS fusioned APA, and DMS functions with support of DDAW & ADDW.
        </p>
        <div class="supports-box">
          <div class="supports-card">
            <div class="supports-title">
              <span>Supports</span>
            </div>
            <div class="supports-content">
              <h3 class="soc-platforms-black h3-text mt40">Platforms</h3>
              <p class="p-text pl-30 mb-24 line-h-24">TI (TDA4 etc)</p>
              <h3 class="functions-black h3-text">Functions</h3>
              <p class="p-text-1">
                • Active Safety - AEB/FCW, LKA/ELK (Oncoming/Overtaking/Road
                Edge & Solid), ISA, TSR/TFL, BSD, DOW, RCTA and DMS Hosting
                (DDAW & ADDW).
              </p>
              <p class="p-text-1">
                • Driving Assist - ACC, LCC, ILC, IHC/AHBC.
              </p>
              <p class="p-text-1">• NOA-Highway</p>
              <p class="p-text-1">• Parking Assist - APA, HPA.</p>
            </div>
          </div>
        </div>
        <div class="img-box">
          <img src="/image/adas/westlake.jpg" alt="" />
        </div>
        <!-- <p class="p-text text-center">
          Can integrate with radar/ultrasonic sensors depending on user
          scenarios
        </p> -->
      </div>
    </div>
    <div class="eastlake-container pt80 mt50" id="eastlake">
      <div class="background-img"></div>
      <div class="content-wrapper">
        <h2 class="h2-text nowrap color-ff">
          <span class="icon"></span>
          <span>High-Performance ADAS Domain Controller</span>
        </h2>
        <h3 class="h3-title color-ff mb16">Eastlake</h3>
        <p class="p-text color-fa eastlake-text mb16">
          Based on a BEV+Transformer+OCC architecture, Eastlake offers more high
          performance ADAS functions with various camera combinations (7V~11V)：
        </p>
        <p class="p-text color-fa right-white eastlake-text mb8">
          NOA-Highway (without HD MAP) /Urban Memory Driving /Advanced Parking
          based on Qualcomm 8620 platform or Qualcomm 8775 cabin-driving chip.
        </p>
        <p class="p-text color-fa right-white eastlake-text mb46">
          NOA-city with high-performance platforms such as Qualcomm 8650 or
          Nvidia Orin SoC.
        </p>
        <div class="supports-card mt50">
          <div class="supports-title">
            <span>Supports</span>
          </div>
          <div class="supports-content">
            <h3 class="soc-platforms-black h3-text mt40">Platforms</h3>
            <p class="p-text pl-30 mb-24 line-h-24">
              Qualcomm SA8620, SA8650, SA8775P, Nvidia Orin etc
            </p>
            <h3 class="functions-black h3-text">Functions</h3>
            <p class="p-text pl-30">
              • Active Safety - AEB/FCW, LKA/ELK (Oncoming/Overtaking/Road Edge
              & Solid), ISA, TSR/TFL, BSD, DOW, RCTA and DMS Hosting (DDAW &
              ADDW).
            </p>
            <p class="p-text pl-30">
              • Driving Assist - ACC, LCC, ILC, IHC/AHBC.
            </p>
            <p class="p-text pl-30">• NOA-Highway</p>
            <p class="p-text pl-30">
              • Urban Memory Driving (Vehicle memorizes the route with one
              driver-controlled learning run)
            </p>
            <p class="p-text pl-30">
              • NOA-city (with high-performace platforms)
            </p>
            <p class="p-text pl-30">• Parking Assist - APA, HPA.</p>
          </div>
        </div>

        <div class="mt40">
          <BottomCarousel class="mb8" :sourceList="imgList" />
          <!-- <p class="text-center">
            Can integrate with radar/ultrasonic sensors depending on user
            scenarios
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
import BottomCarousel from './components/Carousel.vue';
export default {
  name: 'Adas',
  metaInfo: {
    title:
      'Integrated ADAS Solutions: Intelligent Front Camera Module, Cost-Effective and High-Performance ADAS Domain Controllers',
    meta: [
      {
        name: 'description',
        content: `Arcsoft’s Integrated ADAS (Advanced Driver Assistance Systems) solutions include the Intelligent Front Camera Module — Southlake, the Cost-Effective ADAS Domain Controller — Westlake, and the High-Performance ADAS Domain Controller — Eastlake. These state-of-the-art systems deliver advanced safety and driver assistance features, elevating vehicle intelligence and optimizing performance.`,
      },
      {
        name: 'keywords',
        content: `Integrated ADAS solutions, Advanced Driver Assistance Systems, intelligent front camera module, ADAS domain controller, cost-effective ADAS, high-performance ADAS, Southlake camera module, Westlake ADAS controller, Eastlake ADAS controller, automotive safety, driver assistance technology`, // 关键词
      },
    ],
  },
  components: { ImageWithPlaceholder, BottomCarousel },
  data() {
    return {
      form: {
        company: '',
        firstName: '',
        lastName: '',
        email: '',
        areaCode: '+86',
        region: 'EU',
        phone: '',
        comment: '',
        file: null,
      },
      formErrors: {},
      fileName: null,
      imgList: [
        {
          key: 1,
          name: '7V/9V Mid-performance Solutions',
          url: '/image/adas/eastlake1.jpg',
        },
        {
          key: 2,
          name: '8V/9V Mid-performance Solutions',
          url: '/image/adas/eastlake2.jpg',
        },
        {
          key: 3,
          name: '11V High-performance Solutions',
          url: '/image/adas/eastlake3.jpg',
        },
      ],
    };
  },
  methods: {
    validateForm() {
      this.formErrors = {};

      if (!this.form.company) {
        this.formErrors.company = 'Company name is required';
      }
      if (!this.form.firstName) {
        this.formErrors.firstName = 'First name is required';
      }
      if (!this.form.lastName) {
        this.formErrors.lastName = 'Last name is required';
      }
      if (!this.form.email) {
        this.formErrors.email = 'Email is required';
      } else if (!this.isValidEmail(this.form.email)) {
        this.formErrors.email = 'Invalid email format';
      }
      //   if (!this.form.phone) {
      //     this.formErrors.phone = "Phone number is required";
      //   }

      return Object.keys(this.formErrors).length === 0;
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    handleSubmit() {
      if (this.validateForm()) {
        alert('Form submitted successfully!');
        // 可以处理表单数据，例如发送到服务器
      }
    },
    handleReset() {
      this.form = {
        company: '',
        firstName: '',
        lastName: '',
        email: '',
        region: 'EU',
        areaCode: '+86', // 重置为默认区号
        phone: '',
        comment: '',
        file: null,
      };
      this.formErrors = {}; // 重置校验错误信息
      this.fileName = null; // 清除文件名
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.file = file;
        this.fileName = file.name;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
  .banner-title {
    font-size: 54px;
    position: absolute;
    bottom: 306px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    color: #fafafa;
  }
  .overview-box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 120px;
    background: linear-gradient(to bottom, #eaeaeb, #fff);
    display: flex;
    .min-title {
      font-size: 16px;
    }
    a {
      font-size: 20px;
      width: 500px;
      list-style: none;
      border: 1px solid #d9d9d9;
      border-left: initial;
      box-sizing: border-box;
      padding: 15px 25px;
      position: relative;
      // .title {
      //   display: inline-block;
      //   width: 300px;
      // }
      &:hover::after {
        display: block;
      }
      &:hover {
        color: #e83021;
      }
      &:first-child {
        width: 400px;
      }
    }
    a:first-child {
      border-left: 1px solid #d9d9d9;
    }

    .southlake {
      background: url(/image/adas/car-icon.svg) no-repeat 95% 18px e('/') 22px,
        url(/image/adas/go.svg) no-repeat 25px 80px e('/') 22px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: none;
        background: url(/image/adas/car-icon-action.svg) no-repeat 95% 18px
            e('/') 22px,
          url(/image/adas/go-action.svg) no-repeat 25px 80px e('/') 22px;
      }
    }
    .westlake {
      background: url(/image/adas/car-icon.svg) no-repeat 95% 18px e('/') 22px,
        url(/image/adas/go.svg) no-repeat 25px 80px e('/') 22px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: none;
        background: url(/image/adas/car-icon-action.svg) no-repeat 95% 18px
            e('/') 22px,
          url(/image/adas/go-action.svg) no-repeat 25px 80px e('/') 22px;
      }
    }
    .eastlake {
      background: url(/image/adas/car-icon.svg) no-repeat 95% 18px e('/') 22px,
        url(/image/adas/go.svg) no-repeat 25px 80px e('/') 22px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: none;
        background: url(/image/adas/car-icon-action.svg) no-repeat 95% 18px
            e('/') 22px,
          url(/image/adas/go-action.svg) no-repeat 25px 80px e('/') 22px;
      }
    }
  }
  img {
    width: 100%;
  }
}

// 公共部分
.content-wrapper {
  width: 960px;
  margin: 0 auto;
}

.p-text {
  color: #222;
  line-height: 18px;
  margin-bottom: 8px;
  max-width: 958px;
  &.right {
    padding: 0 32px;
    background: url('/arrow.svg') no-repeat 16px 6px;
  }
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mr-0 {
  margin-right: 0;
}

.pl-30 {
  padding-left: 30px;
}

.line-h-24 {
  line-height: 24px;
}

.indent-32 {
  text-indent: 32px;
}

.nowrap {
  white-space: nowrap;
}

.color-fa {
  color: #fafafa;
}

.color-ff {
  color: #fff;
}

.p-text-1 {
  line-height: 24px;
  padding-left: 32px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

.h2-text {
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 16px;
  display: flex;
  .icon {
    position: relative;
    padding-left: 24px;
    &::after {
      content: '';
      display: block;
      height: 40px;
      width: 4px;
      background-color: #e83021;
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
}

.h3-text {
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  text-indent: 32px;
  margin-bottom: 8px;
}

// Info
.info-container {
  margin-top: 140px;
  margin-bottom: 40px;
}

.describe-table {
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 12px;
    color: #222;
  }

  th,
  td {
    border: 1px solid #d9d9d9;
    text-align: left;
    padding: 8px 16px;
    width: 240px;
  }
  th {
    background-color: #fafafa;
    font-weight: normal;
  }
  td {
    vertical-align: middle;
    height: 50px;
  }
  .table-bottom td {
    background-color: #fafafa;
    height: unset;
  }
  .category {
    color: #a5a5a5;
    display: block;
  }
}

// Southlake
.southlake-container {
  box-sizing: border-box;
  height: 570px;
  padding-top: 80px;
  background: #212322 url('/image/adas/southlake1.png') no-repeat right;
  color: #fafafa;
  .p-text-1 {
    color: #fafafa;
    max-width: 568px;
  }
  .bottom-title {
    color: #fafafa;
    max-width: 1440px;
    text-align: right;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1280px) {
  .southlake-container {
    background: #212322 url('/image/adas/southlake.png') no-repeat right;
    background-size: 635px;
  }
}

.southlake-text {
  max-width: 605px;
  margin-bottom: 24px;
}

.right-white {
  padding-left: 52px;
  background: url('../../../public/image/apa/arrow.svg') no-repeat 37px 5px;
}
.camera-locations-white {
  background: url('/image/adas/cameralocation1.svg') no-repeat;
  color: #fff;
  background-size: 24px 24px;
}
.sensors-white {
  color: #fff;
  background: url('/image/adas/sensors1.svg') no-repeat;
  background-size: 24px 24px;
}
.soc-platforms-black {
  color: #222;
  background: url('/image/adas/platform1.svg') no-repeat;
  background-size: 24px 24px;
}
.camera-locations-black {
  color: #222;
  background: url('/image/adas/cameralocation.svg') no-repeat;
  background-size: 24px 24px;
}
.sensors-black {
  color: #222222;
  background: url('/image/adas/sensors.svg') no-repeat;
  background-size: 24px 24px;
}

.functions-white {
  color: #fff;
  background: url('/image/adas/functions.svg') no-repeat;
  background-size: 24px 24px;
}

.functions-black {
  color: #222222;
  background: url('/image/adas/fuctionsblack.svg') no-repeat;
  background-size: 24px 24px;
}

// Westlake
.westlake-container {
  .img-box {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.supports-box {
  // padding: 60px 0;
  // background: url("/image/adas/adas2.png") no-repeat right;
  // background-size: 695px 440px;
}
.supports-card {
  // width: 550px;
  // height: 334px;
  background-color: rgba(255, 255, 255, 0.9);
  border: solid 1px #d9d9d9;
  backdrop-filter: blur(10px);
  position: relative;
  .supports-title {
    width: 109px;
    height: 41px;
    line-height: 41px;
    text-align: center;
    background-color: #e83021;
    border-radius: 2px;
    color: #ffffff;
    font-size: 18px;
    position: absolute;
    left: -1px;
    top: -20px;
  }
  .supports-content {
    padding: 0 24px;
    padding-bottom: 20px;
    .p-text {
      line-height: 24px;
    }
  }
}

// Eastlake
.eastlake-container {
  // background: url("/image/adas/adas3-1920.jpg") no-repeat;
  // background-size: 100%;
  position: relative;
  .background-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 480px;
    background-color: #222;
    z-index: -1;
  }
  .eastlake-text {
    max-width: 640px;
  }
  .supports-card {
    width: 100%;
  }
}

.h3-title {
  font-size: 30px;
}
</style>
