var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adas pb50"},[_c('div',{staticClass:"banner"},[_c('ImageWithPlaceholder',{attrs:{"src":"/image/adas/adas.jpg","alt":"Loaded Image"}}),_c('h1',{staticClass:"banner-title"},[_vm._v("Integrated ADAS Solutions")]),_vm._m(0)],1),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"eastlake-container pt80 mt50",attrs:{"id":"eastlake"}},[_c('div',{staticClass:"background-img"}),_c('div',{staticClass:"content-wrapper"},[_vm._m(4),_c('h3',{staticClass:"h3-title color-ff mb16"},[_vm._v("Eastlake")]),_c('p',{staticClass:"p-text color-fa eastlake-text mb16"},[_vm._v(" Based on a BEV+Transformer+OCC architecture, Eastlake offers more high performance ADAS functions with various camera combinations (7V~11V)： ")]),_c('p',{staticClass:"p-text color-fa right-white eastlake-text mb8"},[_vm._v(" NOA-Highway (without HD MAP) /Urban Memory Driving /Advanced Parking based on Qualcomm 8620 platform or Qualcomm 8775 cabin-driving chip. ")]),_c('p',{staticClass:"p-text color-fa right-white eastlake-text mb46"},[_vm._v(" NOA-city with high-performance platforms such as Qualcomm 8650 or Nvidia Orin SoC. ")]),_vm._m(5),_c('div',{staticClass:"mt40"},[_c('BottomCarousel',{staticClass:"mb8",attrs:{"sourceList":_vm.imgList}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-box"},[_c('a',{staticClass:"southlake",attrs:{"href":"#southlake"}},[_c('span',{staticClass:"title"},[_vm._v("Intelligent Front Camera Module ")]),_c('br'),_c('span',{staticClass:"title min-title"},[_vm._v("Southlake")])]),_c('a',{staticClass:"westlake",attrs:{"href":"#westlake"}},[_c('span',{staticClass:"title"},[_vm._v("Cost-Effective ADAS Domain Controller ")]),_c('br'),_c('span',{staticClass:"title min-title"},[_vm._v("Westlake")])]),_c('a',{staticClass:"eastlake",attrs:{"href":"#eastlake"}},[_c('span',{staticClass:"title"},[_vm._v("High-Performance ADAS Domain Controller")]),_c('br'),_c('span',{staticClass:"title min-title"},[_vm._v("Eastlake")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-container mt120",attrs:{"id":"info"}},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"describe"},[_c('p',{staticClass:"p-text"},[_vm._v(" The automotive industry is experiencing revolutionary changes around the world. As one of the leading automotive visual sensing solutions providers, ArcSoft has worked with camera makers and SoC partners to develop a series of integrated ADAS product solutions tailored to various OEM customer needs and their budgetary requirements. OEMs can use the ArcSoft integrated ADAS solutions to offer advanced driving assistance functions. ")]),_c('p',{staticClass:"p-text"},[_vm._v(" With the ArcSoft integrated ADAS solutions, OEM can meet regional regulatory requirements such as GSR (e.g., FCW, AEB, ISA) or E-NCAP evaluation criteria, and provide safer and more comfortable driving with ACC, LCC, and NOA functions. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"southlake-container pt80 mt50",attrs:{"id":"southlake"}},[_c('div',{staticClass:"content-wrapper"},[_c('h2',{staticClass:"h2-text nowrap color-fa"},[_c('span',{staticClass:"icon"}),_c('span',[_vm._v("Intelligent Front Camera Module")])]),_c('h3',{staticClass:"h3-title mb16"},[_vm._v("Southlake")]),_c('p',{staticClass:"p-text color-fa southlake-text"},[_vm._v(" Southlake is a standalone intelligent ADAS camera module developed on cost-effective SoC platforms to support advanced driving assistance functions that comply with regional regulations and general E-NCAP evaluation criteria. It also has an additional camera interface and capacity to support the DMS functions including DDAW & ADDW. ")]),_c('h3',{staticClass:"functions-white h3-text"},[_vm._v("Functions")]),_c('p',{staticClass:"p-text-1"},[_vm._v(" • Active Safety - AEB/FCW, LKA/ELK (Oncoming/Overtaking/Road Edge & Solid), ISA, TSR/TFL and DMS (DDAW & ADDW). ")]),_c('p',{staticClass:"p-text-1"},[_vm._v("• Driving Assist - ACC, LCC, IHC/AHBC.")]),_c('p',{staticClass:"p-text-1"},[_vm._v(" • ILC, BSD, DOW, RCTA (with additional two rear corner radars). ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"westlake-container pt80",attrs:{"id":"westlake"}},[_c('div',{staticClass:"content-wrapper"},[_c('h2',{staticClass:"h2-text"},[_c('span',{staticClass:"icon"}),_c('span',[_vm._v("Cost-Effective ADAS Domain Controller")])]),_c('h3',{staticClass:"h3-title mb16"},[_vm._v("Westlake")]),_c('p',{staticClass:"p-text westlake-text mb40"},[_vm._v(" Based on cost-effective platforms such as TDA4, ArcSoft developed efficient driving and parking perception algorithms to offer cost-effective ADAS solutions for NOA-Highway, Vision-Only or camera and USS fusioned APA, and DMS functions with support of DDAW & ADDW. ")]),_c('div',{staticClass:"supports-box"},[_c('div',{staticClass:"supports-card"},[_c('div',{staticClass:"supports-title"},[_c('span',[_vm._v("Supports")])]),_c('div',{staticClass:"supports-content"},[_c('h3',{staticClass:"soc-platforms-black h3-text mt40"},[_vm._v("Platforms")]),_c('p',{staticClass:"p-text pl-30 mb-24 line-h-24"},[_vm._v("TI (TDA4 etc)")]),_c('h3',{staticClass:"functions-black h3-text"},[_vm._v("Functions")]),_c('p',{staticClass:"p-text-1"},[_vm._v(" • Active Safety - AEB/FCW, LKA/ELK (Oncoming/Overtaking/Road Edge & Solid), ISA, TSR/TFL, BSD, DOW, RCTA and DMS Hosting (DDAW & ADDW). ")]),_c('p',{staticClass:"p-text-1"},[_vm._v(" • Driving Assist - ACC, LCC, ILC, IHC/AHBC. ")]),_c('p',{staticClass:"p-text-1"},[_vm._v("• NOA-Highway")]),_c('p',{staticClass:"p-text-1"},[_vm._v("• Parking Assist - APA, HPA.")])])])]),_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":"/image/adas/westlake.jpg","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"h2-text nowrap color-ff"},[_c('span',{staticClass:"icon"}),_c('span',[_vm._v("High-Performance ADAS Domain Controller")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"supports-card mt50"},[_c('div',{staticClass:"supports-title"},[_c('span',[_vm._v("Supports")])]),_c('div',{staticClass:"supports-content"},[_c('h3',{staticClass:"soc-platforms-black h3-text mt40"},[_vm._v("Platforms")]),_c('p',{staticClass:"p-text pl-30 mb-24 line-h-24"},[_vm._v(" Qualcomm SA8620, SA8650, SA8775P, Nvidia Orin etc ")]),_c('h3',{staticClass:"functions-black h3-text"},[_vm._v("Functions")]),_c('p',{staticClass:"p-text pl-30"},[_vm._v(" • Active Safety - AEB/FCW, LKA/ELK (Oncoming/Overtaking/Road Edge & Solid), ISA, TSR/TFL, BSD, DOW, RCTA and DMS Hosting (DDAW & ADDW). ")]),_c('p',{staticClass:"p-text pl-30"},[_vm._v(" • Driving Assist - ACC, LCC, ILC, IHC/AHBC. ")]),_c('p',{staticClass:"p-text pl-30"},[_vm._v("• NOA-Highway")]),_c('p',{staticClass:"p-text pl-30"},[_vm._v(" • Urban Memory Driving (Vehicle memorizes the route with one driver-controlled learning run) ")]),_c('p',{staticClass:"p-text pl-30"},[_vm._v(" • NOA-city (with high-performace platforms) ")]),_c('p',{staticClass:"p-text pl-30"},[_vm._v("• Parking Assist - APA, HPA.")])])])
}]

export { render, staticRenderFns }